<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button type="is-primary" @click="addBond()">Agregar bono</b-button>
      </div>
      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteBonds()"
          >Borrar bonos</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de bonos: <b>{{ bonds.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="bonds"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="bonds"
        :checked-rows.sync="checkedBonds"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.DESCRIPCION}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        checkable
        default-sort="_id"
      >
        <template #empty>
          <div class="has-text-centered">No hay bonos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) && !column.notPercentage
              "
            >
              {{ props.row[column.field] }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) && column.notPercentage
              "
            >
              {{ props.row[column.field] }}%
            </p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddBondModal :bondInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddBondModal from "./AddBondModal";

export default {
  name: "BondsList",
  components: {
    AddBondModal,
  },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedBonds: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PRIORIDAD",
          label: "Prioridad",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "COMISION",
          label: "Comisión",
          sortable: true,
          searchable: true,
          display: true,
          notPercentage: true,
        },
      ],
    };
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETBONDS"));

      await Promise.all(allPromises);
    },
    addBond() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBondModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmDeleteBonds() {
      if (this.checkedBonds.length > 0) {
        this.reloadInformation();
        this.$store
          .dispatch("DELETBONDS", {
            deleteBonds: this.checkedBonds,
          })
          .then((response) => {
            if (response == "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se borrarón exitosamente los bonos seleccionados",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar los bonos en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay bonos seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    bonds() {
      return this.$store.getters.BONDS.map((singleBond) => {
        const singleBondWithProps = { ...singleBond };

        singleBondWithProps.TIPO_FOR =
          singleBond.TIPO === "CO" ? "Compras" : "Ventas";

        return singleBondWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
