<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar Familia</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado, </span> si se tienen dudas
      recuerda hacer click en el
      <span class="global-form-hint-color"> signo de interrogación </span> a un
      lado de los nombres de los campos a llenar
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label=""
        expanded
        label-position="inside"
        :type="{ 'is-danger': categoryRequiredErrors.CLAVE_FAM }"
        :message="{
          'La clave de la familia no es valida':
            categoryRequiredErrors.CLAVE_FAM,
        }"
      >
        <template slot="label"> Clave de la familia (Requerido) </template>
        <b-input
          placeholder="Ejemplo: Acero"
          expanded
          icon="numeric"
          v-model="newCategory.CLAVE_FAM"
          required
        ></b-input>
      </b-field>
      <b-field expanded label-position="inside">
        <template slot="label"> Descripción de la familia (Opcional) </template>
        <b-input
          placeholder="Ejemplo: Descripción de aceros"
          expanded
          icon="clipboard"
          v-model="newCategory.DESC_FAM"
        ></b-input>
      </b-field>
    </b-field>

    <b-field
      class="special-margin"
      expanded
      label-position="inside"
      v-for="(singleComment, index) in newCategory.COMMENT"
      v-bind:key="index + 1000"
    >
      <template slot="label"> Comentario {{ index + 1 }} (Opcional) </template>
      <b-input
        placeholder="Ejemplo: Familia usada en proveedor 1"
        expanded
        icon="clipboard-text-multiple-outline"
        v-model="singleComment.DESCRIPTION"
      ></b-input>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="AddMainCategory()"
        >Crear familia</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
// @ is an alias to /src
export default {
  name: "AddCategoryModal",
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newCategory: {
        CLAVE_FAM: "",
        DESC_FAM: "",
      },
      categoryRequiredErrors: {
        CLAVE_FAM: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new main category in the database, display messages according to the status
     */
    async AddMainCategory() {
      try {
        this.categoryRequiredErrors = validateFields(
          this.newCategory,
          this.categoryRequiredErrors
        );
        if (checkValidationErrors(this.categoryRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEMAINCATEGORY",
            this.newCategory
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la nueva familia",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la familia en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la familia en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la familia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newCategory = {
        CLAVE_FAM: "",
        DESC_FAM: "",
        COMMENT: [
          {
            COMENTARIO: "",
          },
        ],
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
