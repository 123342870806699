var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"employees-list-controls",attrs:{"grouped":"","group-multiline":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.addBond()}}},[_vm._v("Agregar bono")])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.confirmDeleteBonds()}}},[_vm._v("Borrar bonos")])],1)]),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-container"}),_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1)]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de bonos: "),_c('b',[_vm._v(_vm._s(_vm.bonds.length))])]),_c('div',{staticClass:"global-list-main-container"},[_c('b-table',{ref:"bonds",attrs:{"pagination-position":"both","data":_vm.bonds,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedBonds,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","scrollable":"","checkable":"","default-sort":"_id"},on:{"update:checkedRows":function($event){_vm.checkedBonds=$event},"update:checked-rows":function($event){_vm.checkedBonds=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.DESCRIPCION)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay bonos")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('p',[_vm._v(" "+_vm._s("Sin información")+" ")]):_vm._e(),(
              _vm.checkIfValid(props.row[column.field]) && !column.notPercentage
            )?_c('p',[_vm._v(" "+_vm._s(props.row[column.field])+" ")]):_vm._e(),(
              _vm.checkIfValid(props.row[column.field]) && column.notPercentage
            )?_c('p',[_vm._v(" "+_vm._s(props.row[column.field])+"% ")]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('AddBondModal',{attrs:{"bondInformation":props.row}})],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }