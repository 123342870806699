<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!bondInformation">
      Agregar bono
    </h1>

    <div v-if="!bondInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Entre menor sea la
      prioridad mayor impacto tiene el bono, un bono con prioridad 1 sera tomado
      en cuenta antes que un 2. Cuando se tengas dos prioridades iguales se
      tomara en cuenta el bono mas más antiguo
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field label="Descripción (Requerido)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Bono a vendedores"
          expanded
          icon="file-outline"
          v-model="newBond.DESCRIPCION"
          required
        ></b-input>
      </b-field>
      <b-field label="Prioridad (Requerido)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 1"
          expanded
          icon="file-outline"
          v-model="newBond.PRIORIDAD"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin required-input">
      <b-field label="Tipo (Requerido)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Ventas"
          expanded
          icon="account-group-outline"
          v-model="newBond.TIPO"
          required
        >
          <option value="">Seleccione un tipo</option>
          <option value="CO">Compras</option>
          <option value="VE">Ventas</option>
        </b-select>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de las reglas del bono</h3>

    <div>
      <b-field grouped class="special-margin">
        <b-field label="Comisión (Requerido)" expanded label-position="inside">
          <PercentageInput
            placeholder="Ejemplo: Bono a vendedores"
            expanded
            icon="file-outline"
            v-model="newBond.COMISION"
            required
          />
        </b-field>
      </b-field>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox special-margin"
        true-value="S"
        false-value="N"
        v-model="newBond.APLICA_CANTIDAD"
      >
        Aplicar a total de venta o compra
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox special-margin"
        true-value="S"
        false-value="N"
        v-model="newBond.APLICA_CANTIDAD_COMISION"
      >
        Aplicar a total de comision mensual
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox special-margin"
        true-value="S"
        false-value="N"
        v-model="newBond.APLICA_FAMILIAS"
      >
        Aplicar a familias y sub familias
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox special-margin"
        true-value="S"
        false-value="N"
        v-model="newBond.TIENE_CADUCIDAD"
      >
        Tiene fecha de caducidad
      </b-checkbox>

      <b-field
        grouped
        class="special-margin"
        v-if="newBond.APLICA_CANTIDAD === 'S'"
      >
        <b-field
          label="Limite inferior de total de venta o compra (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 1000"
            expanded
            icon="file-outline"
            v-model="newBond.LIMITE_INFERIOR"
          ></b-input>
        </b-field>
        <b-field
          label="Limite superior de total de venta o compra (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 10000"
            expanded
            icon="file-outline"
            v-model="newBond.LIMITE_SUPERIOR"
          ></b-input>
        </b-field>
      </b-field>

      <b-field
        grouped
        class="special-margin"
        v-if="newBond.APLICA_CANTIDAD_COMISION === 'S'"
      >
        <b-field
          label="Limite inferior de comisión mensual (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 1000"
            expanded
            icon="file-outline"
            v-model="newBond.LIMITE_INFERIOR_COMISION"
          ></b-input>
        </b-field>
        <b-field
          label="Limite superior de comisión mensual (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 10000"
            expanded
            icon="file-outline"
            v-model="newBond.LIMITE_SUPERIOR_COMISION"
          ></b-input>
        </b-field>
      </b-field>

      <div class="special-margin" v-if="newBond.APLICA_FAMILIAS === 'S'">
        <b-field
          label="Familia (Opcional)"
          expanded
          label-position="inside"
          class="special-margin"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="cog"
            v-model="newBond.CLAVE_FAM"
            @input="handleCategorySelect"
          >
            <option :value="null">No seleccionar una familia</option>
            <option
              v-for="item in categories"
              :key="item.CLAVE_FAM"
              :value="item"
            >
              {{ item.CLAVE_FAM }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Subfamilia (Opcional)"
          expanded
          label-position="inside"
          class="special-margin"
        >
          <b-select
            placeholder="Ejemplo: Producto"
            expanded
            icon="shape-outline"
            v-model="newBond.CLAVE_SFAM"
            @input="handleSCategorySelect"
          >
            <option :value="null">No Seleccionar una subfamilia</option>
            <option
              v-for="item in filteredSCategories"
              :key="item.CLAVE_SFAM"
              :value="item"
            >
              {{ item.CLAVE_SFAM }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Subsubfamilia (Opcional)"
          expanded
          label-position="inside"
          class="special-margin"
        >
          <b-select
            placeholder="Ejemplo: Herramientas"
            expanded
            icon="shape-outline"
            v-model="newBond.CLAVE_SSFAM"
            @input="handleSSCategrySelect"
          >
            <option :value="null">No seleccionar una subsubfamilia</option>
            <option
              v-for="item in filteredSSCategories"
              :key="item.CLAVE_SSFAM"
              :value="item"
            >
              {{ item.CLAVE_SSFAM }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Subsubsubfamilia (Opcional)"
          expanded
          label-position="inside"
          class="special-margin"
        >
          <b-select
            placeholder="Ejemplo: Metalicas"
            expanded
            icon="shape-outline"
            v-model="newBond.CLAVE_SSSFAM"
          >
            <option :value="null">No seleccionar una subsubsubfamilia</option>
            <option
              v-for="item in filteredSSSCategories"
              :key="item.CLAVE_SSSFAM"
              :value="item"
            >
              {{ item.CLAVE_SSSFAM }}
            </option>
          </b-select>
        </b-field>
      </div>

      <b-field
        grouped
        class="special-margin"
        v-if="newBond.TIENE_CADUCIDAD === 'S'"
      >
        <b-field expanded label-position="inside">
          <template slot="label">
            Fin (Opcional)
            <b-tooltip multilined label="Feha a la que termina el bono">
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-datepicker
            :datetime-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :datepicker="datepicker"
            v-model="newBond.FECHA_FIN"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datepicker>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="bondInformation">
      <b-button type="is-success" @click="saveBond(1)">
        Actualizar bono
      </b-button>

      <b-button type="is-success" @click="disableBond()" v-if="STATUS === 'AC'">
        Desactivar bono
      </b-button>

      <b-button
        type="is-success"
        @click="activateBond()"
        v-if="STATUS === 'DE'"
      >
        Activar bono
      </b-button>

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!bondInformation">
      <b-button type="is-success" @click="saveBond(0)">Guardar bono</b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import AddCategoryModal from "../../Global/components/AddCategoryModal";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddBondModal",
  props: ["bondInformation"],
  directives: { cleave },
  components: {},
  data() {
    return {
      newBond: {
        DESCRIPCION: "",
        TIPO: "",
        PRIORIDAD: 1,
        APLICA_CANTIDAD: "N",
        LIMITE_INFERIOR: 0,
        LIMITE_SUPERIOR: 0,
        APLICA_CANTIDAD_COMISION: "N",
        LIMITE_INFERIOR_COMISION: 0,
        LIMITE_SUPERIOR_COMISION: 0,
        APLICA_FAMILIAS: "N",
        CANTIDAD: 0,
        CLAVE_FAM: null,
        CLAVE_SFAM: null,
        CLAVE_SSFAM: null,
        CLAVE_SSSFAM: null,
        COMISION: 0,
        TIENE_CADUCIDAD: "N",
        FECHA_FIN: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        ),
      },
      moment: moment,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
      masks: Masks,
      errors: {
        DESCRIPCION: false,
        TIPO: false,
        PRIORIDAD: false,
      },
    };
  },
  async mounted() {
    // Fill the article information in form fields
    if (this.bondInformation) {
      // Fill the form with received information
      fillObjectForm(this.bondInformation, this.newBond);
      this.newBond._id = this.bondInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveBond(type) {
      try {
        this.errors = validateFields(this.newBond, this.errors);
        if (checkValidationErrors(this.errors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEBOND" : "EDITBOND",
            this.newBond
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el bono",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el bono en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el bono en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el bono en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddCategory() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCategoryModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleCategorySelect() {
      if (this.newBond.CLAVE_FAM === "addNewItem") {
        this.openAddCategory();
        setTimeout(() => {
          this.newBond.CLAVE_FAM = null;
        }, 500);
      }
      this.newBond.CLAVE_SFAM = null;
      this.newBond.CLAVE_SSFAM = null;
      this.newBond.CLAVE_SSSFAM = null;
    },
    handleSCategorySelect() {
      this.newBond.CLAVE_SSFAM = null;
      this.newBond.CLAVE_SSSFAM = null;
    },
    handleSSCategrySelect() {
      this.newBond.CLAVE_SSSFAM = null;
    },
    async disableBond() {
      try {
        let response = await this.$store.dispatch("DISABLEBOND", {
          bondId: this.bondInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se desactivó exitosamente el bono",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al desactivar el bono en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al desactivar el bono en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async activateBond() {
      try {
        let response = await this.$store.dispatch("ACTIVATEBOND", {
          bondId: this.bondInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se desactivó exitosamente el bono",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al desactivar el bono en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al desactivar el bono en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    categories() {
      /**
       * Map main categories with selected sub categories
       */
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    // Sub categories filtered by category selected
    filteredSCategories() {
      if (this.newBond.CLAVE_FAM) {
        return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
          let singleCategoryWithProp = { ...singleCategory };
          return singleCategoryWithProp;
        }).filter((singleCategory) => {
          return singleCategory.CLAVE_FAM === this.newBond.CLAVE_FAM.CLAVE_FAM;
        });
      }
      return [];
    },
    // Sub sub categories filtered by sub category selected
    filteredSSCategories() {
      if (this.newBond.CLAVE_SFAM) {
        return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
          return { ...singleCategory };
        }).filter((singleCategory) => {
          return (
            singleCategory.CLAVE_SFAM === this.newBond.CLAVE_SFAM.CLAVE_SFAM
          );
        });
      }
      return [];
    },
    // Sub sub sub categories filtered by sub sub category selected
    filteredSSSCategories() {
      if (this.newBond.CLAVE_SSFAM) {
        return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
          return { ...singleCategory };
        }).filter((singleCategory) => {
          return (
            singleCategory.CLAVE_SSFAM === this.newBond.CLAVE_SSFAM.CLAVE_SSFAM
          );
        });
      }
      return [];
    },
  },
};
</script>

<style scoped>
@import "../style/AddEmployeeModal.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
@import "../../Global/style/Global.css";
</style>
